import React from 'react'
import HeroSection from '../components/hero_section/HeroSection.jsx'

import FeatureWrapper from '../components/feature_wrapper/FeatureWrapper.jsx'

import bannerimage from '../components/persist_assets/bannerimage.svg'

import HomeBanner from '../components/banner1m/Homebanner.jsx'
import SpecializationAreas from './tradex/SpecializationAreas.jsx'
import Aboutus from './about_us/Aboutus.jsx'




const Main = () => {
  return (
    <div>
      <HeroSection
      image={bannerimage}
      heading1="Achieve Your Dreams with "
      heading2 = "  Global Intern  "
      text1="We aim to cultivate the future leaders of tomorrow."
      text2="Our Global Intern program provides hands-on experience, equips you with essential skills, and lets you earn by selling your projects."
      buttonText="Explore Projects"
      buttonLink="/domains"
      component = {<SpecializationAreas/>}
    />
      
      <SpecializationAreas/>
      <HomeBanner/>
      <Aboutus/>
      
      <FeatureWrapper />
      
    </div>
  )
}

export default Main
