import React from 'react';
import './HowItWorks.css';

const HowItWorks = () => {
  const steps = [
    {
      title: "Step 1: Browse Projects",
      description: "Explore various internship projects completed by talented interns.",
      icon: "fas fa-search",
      
    },
    {
      title: "Step 2: Connect with Interns",
      description: "Reach out to interns to learn more about their skills and experiences.",
      icon: "fas fa-comments",
      
    },
    {
      title: "Step 3: Hire for Projects",
      description: "Hire interns for your freelance projects based on their expertise.",
      icon: "fas fa-handshake",
      
    }
  ];

  return (
    <section className="how-it-works">
      <div className="section-header">
        <h2>How It Works</h2>
        <p>Simple steps to find and hire interns for your projects.</p>
      </div>
      <div className="steps">
        {steps.map((step, index) => (
          <div className="step" key={index} style={{ backgroundColor: step.backgroundColor }}>
            <i className={step.icon}></i>
            <h3>{step.title}</h3>
            <p>{step.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default HowItWorks;
